<template>
  <div>
    <a-page-header
      class="page-header"
      :ghost="false"
      style="margin-bottom: 16px"
    >
      <template #title>
        <a-space size="middle">
          <a-icon type="user"></a-icon>
          <span>组织设置</span>
        </a-space>
      </template>
      <span>组织设置包含角色、权限等设置。</span>
    </a-page-header>

    <a-card :bordered="false" style="margin-bottom: 16px">
      <organization-table></organization-table>
    </a-card>
  </div>
</template>

<script>
import OrganizationTable from '@/components/table/OrganizationTable/index.vue'

export default {
  name: 'Organization',
  components: {
    OrganizationTable
  },
  data () {
    return {
    }
  }
}
</script>
