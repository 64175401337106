<template>
  <a-drawer
    :destroy-on-close="true"
    :title="isUpdate ? '组织编辑' : '组织创建'"
    :visible="visible"
    :width="950"
    @close="closeDrawer"
  >
    <a-form-model
      ref="form"
      :label-col="{
        span: 4,
        style: {
          textAlign: 'left'
        }
      }"
      :model="form"
      :rules="rules"
      :wrapper-col="{
        span: 20
      }"
    >
     <a-form-model-item label="名称" prop="name">
        <a-input v-model="form.name" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item label="上级组织" prop="parentId">
        <organization-tree-select
          v-model="form.parentId"
          :filter-id="form.id"
        ></organization-tree-select>
      </a-form-model-item>
      <a-form-model-item label="业务配置" prop="services">
        <service-multi-select v-model="form.services"></service-multi-select>
      </a-form-model-item>
      <a-form-model-item label="资源配置" prop="source_ranges">
        <div
          v-for="(condition, index) in form.source_ranges"
          :key="`condition_${index}`"
          style="display: flex"
        >
          <a-form-model-item
            :prop="`source_ranges[${index}].source_type`"
            style="margin-right: 16px"
            :rules="[
              {
                message: '请选择资源类型',
                required: true,
                trigger: 'change'
              }
            ]"
          >
            <a-select
              v-model="condition.source_type"
              :options="options.sourceType"
              style="width: 160px"
            ></a-select>
          </a-form-model-item>
          <a-form-model-item
            :prop="`source_ranges[${index}].source_target`"
            style="width: 160px"
            :rules="[
              {
                message: '请选择资源范围',
                required: true,
                trigger: 'change'
              }
            ]"
          >
            <a-select
              v-model="condition.source_target"
              :options="options.sourceTarget"
            ></a-select>
          </a-form-model-item>
          <template v-if="condition.source_type">
            <a-form-model-item
              v-if="condition.source_target === 'group'"
              :prop="`source_ranges[${index}].groups`"
              :rules="[
                {
                  message:
                    '请选择' +
                    $t(`source_type.${condition.source_type}`) +
                    '群组',
                  required: true,
                  trigger: 'change'
                }
              ]"
              style="margin-left: 16px"
            >
              <group-select
                v-model="condition.groups"
                :source-type="condition.source_type"
                style="width: 400px"
              ></group-select>
            </a-form-model-item>
            <a-form-model-item
              v-else-if="condition.source_target === 'selected'"
              :prop="`source_ranges[${index}].sources`"
              :rules="[
                {
                  message:
                    '请选择' + $t(`source_type.${condition.source_type}`),
                  required: true,
                  trigger: 'change'
                }
              ]"
              style="margin-left: 16px"
            >
              <multi-source-select
                v-model="condition.sources"
                :source-type="condition.source_type"
                style="width: 400px"
              ></multi-source-select>
            </a-form-model-item>
          </template>
          <a-form-model-item style="margin-left: 16px">
            <a-button
              icon="minus-circle"
              type="link"
              @click="form.source_ranges.splice(index, 1)"
            ></a-button>
          </a-form-model-item>
        </div>
        <a-button
          :block="true"
          icon="plus"
          type="dashed"
          @click="
            form.source_ranges.push({
              source_type: undefined,
              source_target: undefined,
              sources: undefined,
              groups: undefined
            })
          "
        >
          新增资源配置
        </a-button>
      </a-form-model-item>
      <a-form-model-item
        :wrapper-col="{ span: 20, offset: 4 }"
      >
        <a-space size="middle">
          <a-button :loading="loading" type="primary" @click="ok">
            确定
          </a-button>
          <a-button @click="closeDrawer">取消</a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {
  sourceTargets,
  sourceTypes
} from '@/utils/const'
import { createOrganization, getOrganization, updateOrganization } from '@/api/organization'
import MultiSourceSelect from '@/components/select/MultiSourceSelect'
import ServiceMultiSelect from '@/components/select/ServiceMultiSelect'
import OrganizationTreeSelect from '@/components/select/OrganizationTreeSelect.vue'
import GroupSelect from '@/components/select/GroupSelect'

export default {
  name: 'InputDrawer',
  components: {
    ServiceMultiSelect,
    MultiSourceSelect,
    OrganizationTreeSelect,
    GroupSelect
  },
  data () {
    return {
      visible: false,
      isUpdate: false,
      loading: false,
      form: {
        id: '',
        name: '',
        services: [],
        source_ranges: [],
        parentId: ''
      },
      rules: {
        name: [
          {
            message: '请输入名称',
            required: true,
            trigger: 'blur'
          }
        ]
      },
      options: {
        sourceType: sourceTypes.map(value => {
          return {
            title: this.$t(`source_type.${value}`),
            value
          }
        }),
        sourceTarget: sourceTargets.map(value => {
          return {
            title: this.$t(`source_target.${value}`),
            value
          }
        })
      }
    }
  },
  methods: {
    show (id, parentId) {
      if (id) {
        this.isUpdate = true
        this.form.id = id
        getOrganization(id).then(res => {
          const data = res.data
          this.form = {
            id,
            name: data.name,
            services: data.services,
            source_ranges: data.source_ranges,
            parentId: data.parent_id
          }
        })
        this.visible = true
      } else {
        if (parentId) {
          this.form.parentId = parentId
        }
        this.isUpdate = false
        this.visible = true
      }
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const params = {
            name: this.form.name
          }
          if (this.form.parentId) params.parent_id = this.form.parentId
          if (this.form.services.length) {
            params.service_ids = this.form.services.map(
              service => service.key || service.id
            )
          } else params.service_ids = []
          if (this.form.source_ranges.length) {
            params.source_ranges = this.form.source_ranges.map(condition => {
              const data = {
                source_type: condition.source_type,
                source_target: condition.source_target
              }
              if (condition.source_target === 'group') {
                data.group_ids = condition.groups.map(group => group.id)
              } else if (condition.source_target === 'selected') {
                data.source_ids = condition.sources.map(
                  source => source.key || source.id
                )
              }
              return data
            })
          } else params.source_ranges = []
          if (this.form.id) {
            updateOrganization(this.form.id, params)
              .then(res => {
                this.$message.success(res.message)
                this.loading = false
                this.$emit('ok')
              })
              .finally(() => {
                this.closeDrawer()
              })
          } else {
            createOrganization(params)
              .then(res => {
                this.$message.success(res.message)
                this.closeDrawer()
                this.$emit('ok')
              })
              .finally(() => {
                this.loading = false
              })
          }
        }
      })
    },
    closeDrawer () {
      this.form = {
        id: '',
        name: '',
        services: [],
        source_ranges: [],
        parentId: ''
      }
      this.visible = false
      this.isUpdate = false
    }
  }
}
</script>

<style>

</style>
