<template>
  <div>
    <div
      style="
        height: 32px;
        margin-bottom: 16px;
        font-size: 16px;
        line-height: 32px;
      "
    >
      <a-space size="middle" style="float: right">
        <reload-button
          @click="fetch"
        ></reload-button>
      </a-space>
    </div>

    <a-table
      class="custom-table"
      :columns="columns"
      :row-selection="rowSelection"
      :data-source="tableData"
      :pagination="false"
      :indentSize="24"
      :loading="loading"
      rowKey="id"
      :expanded-row-keys.sync="expandedRowKeys"
    >
      <template slot="operation" slot-scope="text, record">
        <create-button
          type="link"
          v-permission="{action: 'base.organization.create', effect: 'disabled'}"
            @click="$refs.inputModal.show(undefined, record.id)"
          ></create-button>
        <edit-button
          v-permission="{action: 'base.organization.update', effect: 'disabled'}"
            @click="$refs.inputModal.show(record.id)"
          ></edit-button>
          <delete-button
          v-permission="{action: 'base.organization.delete', effect: 'disabled'}"
            @confirm="confirm(record.id)"
          ></delete-button>
      </template>
    </a-table>

    <div style="height: 36px; margin-top: 16px;">
      <template v-if="rowSelection.selectedRowKeys.length">
        <span style="line-height: 32px">
          {{ `已选中 ${rowSelection.selectedRowKeys.length} 条` }}
        </span>
        <a
          style="line-height: 32px; margin-left: 4px"
          @click="clearSelectData"
        >
          取消
        </a>
      </template>
    </div>

    <input-drawer ref="inputModal" @ok="confirm"></input-drawer>
  </div>
</template>

<script>
import { deleteOrganization, getOrganizationTreeList } from '@/api/organization'
import DeleteButton from '@/components/button/DeleteButton'
import EditButton from '@/components/button/EditButton'
import CreateButton from '@/components/button/CreateButton'
import InputDrawer from './modules/InputDrawer.vue'
import ReloadButton from '@/components/button/ReloadButton'

export default {
  name: 'OrganizationTable',
  components: {
    DeleteButton,
    EditButton,
    InputDrawer,
    ReloadButton,
    CreateButton
  },
  data () {
    return {
      loading: false,
      rowSelection: {
        selectedRows: [],
        selectedRowKeys: [],
        onChange: (selectedRowKeys, selectedRows) => {
          this.rowSelection.selectedRowKeys = selectedRowKeys
          this.rowSelection.selectedRows = selectedRows

          this.tableSelectData = selectedRows.map(item => {
            return {
              label: item.name,
              key: item.id
            }
          })
          // this.$emit('tableSelectData', this.tableSelectData)
        }
      },
      tableSelectData: [],
      columns: [
        {
          dataIndex: 'name',
          title: '组织名称',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'created_at',
          title: '创建时间',
          width: 200
        },
        {
          dataIndex: 'updated_at',
          title: '更新时间',
          width: 200
        },
        {
          dataIndex: 'operation',
          title: '操作',
          align: 'center',
          fixed: 'right',
          width: 260,
          scopedSlots: {
            customRender: 'operation'
          }
        }
      ],
      tableData: [],
      expandedRowKeys: []
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.loading = true
      getOrganizationTreeList().then(res => {
        this.tableData = res.data
        if (this.tableData.length) this.expandedRowKeys = [this.tableData[0].id]
      }).finally(() => { this.loading = false })
    },
    async confirm (id = '') {
      if (id) {
        await deleteOrganization(id).then(res => {
          this.$message.success(res.message)
          this.tableSelectData.splice(
            this.tableSelectData.findIndex(item => item.key === id),
            1
          )
        })
      }
      this.fetch()
    },
    clearSelectData () {
      this.tableSelectData = []
      this.rowSelection.selectedRows = []
      this.rowSelection.selectedRowKeys = []
    }
  }
}
</script>

<style>

</style>
