var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-drawer',{attrs:{"destroy-on-close":true,"title":_vm.isUpdate ? '组织编辑' : '组织创建',"visible":_vm.visible,"width":950},on:{"close":_vm.closeDrawer}},[_c('a-form-model',{ref:"form",attrs:{"label-col":{
      span: 4,
      style: {
        textAlign: 'left'
      }
    },"model":_vm.form,"rules":_vm.rules,"wrapper-col":{
      span: 20
    }}},[_c('a-form-model-item',{attrs:{"label":"名称","prop":"name"}},[_c('a-input',{attrs:{"max-length":128},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('a-form-model-item',{attrs:{"label":"上级组织","prop":"parentId"}},[_c('organization-tree-select',{attrs:{"filter-id":_vm.form.id},model:{value:(_vm.form.parentId),callback:function ($$v) {_vm.$set(_vm.form, "parentId", $$v)},expression:"form.parentId"}})],1),_c('a-form-model-item',{attrs:{"label":"业务配置","prop":"services"}},[_c('service-multi-select',{model:{value:(_vm.form.services),callback:function ($$v) {_vm.$set(_vm.form, "services", $$v)},expression:"form.services"}})],1),_c('a-form-model-item',{attrs:{"label":"资源配置","prop":"source_ranges"}},[_vm._l((_vm.form.source_ranges),function(condition,index){return _c('div',{key:`condition_${index}`,staticStyle:{"display":"flex"}},[_c('a-form-model-item',{staticStyle:{"margin-right":"16px"},attrs:{"prop":`source_ranges[${index}].source_type`,"rules":[
            {
              message: '请选择资源类型',
              required: true,
              trigger: 'change'
            }
          ]}},[_c('a-select',{staticStyle:{"width":"160px"},attrs:{"options":_vm.options.sourceType},model:{value:(condition.source_type),callback:function ($$v) {_vm.$set(condition, "source_type", $$v)},expression:"condition.source_type"}})],1),_c('a-form-model-item',{staticStyle:{"width":"160px"},attrs:{"prop":`source_ranges[${index}].source_target`,"rules":[
            {
              message: '请选择资源范围',
              required: true,
              trigger: 'change'
            }
          ]}},[_c('a-select',{attrs:{"options":_vm.options.sourceTarget},model:{value:(condition.source_target),callback:function ($$v) {_vm.$set(condition, "source_target", $$v)},expression:"condition.source_target"}})],1),(condition.source_type)?[(condition.source_target === 'group')?_c('a-form-model-item',{staticStyle:{"margin-left":"16px"},attrs:{"prop":`source_ranges[${index}].groups`,"rules":[
              {
                message:
                  '请选择' +
                  _vm.$t(`source_type.${condition.source_type}`) +
                  '群组',
                required: true,
                trigger: 'change'
              }
            ]}},[_c('group-select',{staticStyle:{"width":"400px"},attrs:{"source-type":condition.source_type},model:{value:(condition.groups),callback:function ($$v) {_vm.$set(condition, "groups", $$v)},expression:"condition.groups"}})],1):(condition.source_target === 'selected')?_c('a-form-model-item',{staticStyle:{"margin-left":"16px"},attrs:{"prop":`source_ranges[${index}].sources`,"rules":[
              {
                message:
                  '请选择' + _vm.$t(`source_type.${condition.source_type}`),
                required: true,
                trigger: 'change'
              }
            ]}},[_c('multi-source-select',{staticStyle:{"width":"400px"},attrs:{"source-type":condition.source_type},model:{value:(condition.sources),callback:function ($$v) {_vm.$set(condition, "sources", $$v)},expression:"condition.sources"}})],1):_vm._e()]:_vm._e(),_c('a-form-model-item',{staticStyle:{"margin-left":"16px"}},[_c('a-button',{attrs:{"icon":"minus-circle","type":"link"},on:{"click":function($event){return _vm.form.source_ranges.splice(index, 1)}}})],1)],2)}),_c('a-button',{attrs:{"block":true,"icon":"plus","type":"dashed"},on:{"click":function($event){return _vm.form.source_ranges.push({
            source_type: undefined,
            source_target: undefined,
            sources: undefined,
            groups: undefined
          })}}},[_vm._v(" 新增资源配置 ")])],2),_c('a-form-model-item',{attrs:{"wrapper-col":{ span: 20, offset: 4 }}},[_c('a-space',{attrs:{"size":"middle"}},[_c('a-button',{attrs:{"loading":_vm.loading,"type":"primary"},on:{"click":_vm.ok}},[_vm._v(" 确定 ")]),_c('a-button',{on:{"click":_vm.closeDrawer}},[_vm._v("取消")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }