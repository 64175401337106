<template>
  <a-tree-select
    :value="defaultValue"
    :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }"
    :label-in-value="false"
    :tree-data="treeData"
    :multiple="false"
    :replace-fields="replaceFields"
    @change="change"
    :disabled="disabled"
  ></a-tree-select>
</template>

<script>
import { getOrganizationTreeList } from '@/api/organization'

export default {
  name: 'OrganizationTreeSelect',
  props: {
    value: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    filterId: {
      type: String
    }
  },
  data () {
    return {
      treeData: [],
      replaceFields: {
        children: 'children',
        title: 'name',
        value: 'id'
      },
      defaultValue: this.value
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      getOrganizationTreeList().then(res => {
        this.treeData = res.data
        if (this.filterId) {
          this.treeData = this.filterData(this.treeData)
        }
      })
    },
    change (v) {
      this.$emit('input', v)
    },
    filterData (data) {
      return data.filter(item => {
        if (item.id === this.filterId) {
          item.disabled = true
        }
        if (item.children && item.children.length) {
          item.children = this.filterData(item.children)
        }
        return true
      })
    }
  },
  watch: {
    value (v) {
      if (v) {
        this.defaultValue = v
      }
    }
  }
}
</script>

<style>

</style>
