import request from '@/utils/request'

const urlPrefix = '/organizations'

export function getOrganization (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function createOrganization (data) {
  return request.post(urlPrefix, data)
}

export function getOrganizationList (params) {
  return request.get(urlPrefix, { params })
}

export function updateOrganization (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function deleteOrganization (id) {
  return request.delete(`${urlPrefix}/${id}`)
}

export function getOrganizationTreeList () {
  return request.get(`${urlPrefix}/tree`)
}
